import { Button,TextField } from '@mui/material';
import GoogleLogin from 'react-google-login';
import { form_obj,req,Storage } from '../CommonLibrary';

export default function SignInForEntryModal(args){
    const {setVideos,videos} = args;
    let store = new Storage();
    const responseGoogle = async(response) => {
        let data = {
            name:response.profileObj.name,
            email:response.profileObj.email,
            entry:args.entry,
            google_response:response
        }
        let resp = await req("POST","/add_entry",data);
        if(resp.token){
            store.set("_rf3vc_auth",resp.token);
        }
    
    }
    const handeSignIn = async(e) => {
        e.preventDefault();
        let data = form_obj(e.target);
        data.entry = args.entry;
        let response = await req("POST","/add_entry",data);
        if(response.token){
            store.set("_rf3vc_auth",response.token);
        }
        if(response.data){
            setVideos([response.data.html,...videos]);
        }
        //todo hide modal, show toaster message
        console.log("Sign in response",response);
    }
  
    return(<>
    <h2>Almost done</h2>
    <p>We need to know who you are in case you win.</p>
    <form className='who-are-you-form' onSubmit={handeSignIn}>
    <TextField name="name" className='mb-2' fullWidth required min="3" id="outlined-basic" label="Name" variant="outlined" />
    <TextField name="email"  className='mb-2' fullWidth type="email" id="outlined-basic" label="Email" variant="outlined" />
    <Button type="submit" className='mb-2' fullWidth variant='contained'>Enter Contest</Button>
    </form>
    <GoogleLogin
    className='w-100'
    clientId="388528219724-etp5582e4ftne8rk5ddhp33sd8nq8mie.apps.googleusercontent.com"
    buttonText="Sign in to enter"
    onSuccess={responseGoogle}
  />
    </>);
}