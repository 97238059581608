import React, { useState, useEffect } from 'react';
import { Modal, Box, Grid } from '@mui/material';
import { Rating } from '@smastrom/react-rating';
import ShareIcon from '@mui/icons-material/Share';
import { req, Storage } from '../CommonLibrary';
import '@smastrom/react-rating/style.css'
import {
  EmailShareButton,
  FacebookShareButton,
  LineShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,

} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LineIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";
export default function SharedModal(props) {

  let store = new Storage();
  const voteModal = props.showVoteModal;
  const { sharedModal } = props;
  const [showSharedModal, setShowSharedModal] = useState(false);
  const handleCloseSharedModal = () => setShowSharedModal(false);
  let userRating = 0;
  const [rating, setRating] = useState(userRating);//todo add in usere's rating
  const setRatingHandler = (rating) => {
    setRating(rating);
    let user = store.get("_rf3vc_auth");
    if (!user) {//show modal letting them know they need to sign in to vote and they can win something
      voteModal({ rating, entry: props.video.entry_id });
    }
    let result = req("POST", "/vote", { rating, entry: props.video.entry_id });

  }
  let shareUrl = window.location.href;
  if (shareUrl.includes("#")) {
    shareUrl = shareUrl.substring(0, shareUrl.indexOf("#"));
  }//if there's already tag clear it and use the new one
  shareUrl = shareUrl + "#share=" + sharedModal.entry_id;
  console.log("shareUrl", shareUrl);
  const makeHTML = (entry) => {
    let docHeight = window.innerHeight-150; //viewport height minus header and footer
    if (!entry?.provider_name) return;
    if (entry.provider_name === "instagram") {
      return `<iframe loading="lazy" height="${docHeight}px" sandbox="allow-scripts allow-presentation allow-same-origin" class="share-ig-video" src="https://www.instagram.com/reel/${entry.id}/embed/" title="instagram post" frameborder="0" ></iframe>`
    }
    else if (entry.provider_name === "YouTube") {
      return `<iframe loading="lazy" height="${docHeight}px"  sandbox="allow-scripts allow-presentation allow-same-origin" class="share-yt-video" src="https://www.youtube.com/embed/${entry.id}" title="YouTube video player" frameborder="0" ></iframe>`
    }
    else if (entry.provider_name === "TikTok") {
      return `<iframe loading="lazy"  height="${docHeight}px" sandbox="allow-scripts allow-presentation allow-same-origin" class="share-tt-video" src="https://www.tiktok.com/embed/v2/${entry.id}" ></iframe>`;
    }
  }
  useEffect(() => {
    console.log("SharedModal useEffect", sharedModal);
    if (sharedModal) {
      setShowSharedModal(true);
    }
  }, [sharedModal]
  )

  return (<Modal
    open={showSharedModal}
    onClose={handleCloseSharedModal}
    aria-labelledby="shared-contest-modal"
    aria-describedby="shared-contest-modal"
  >
    <Box className="shared-modal">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div className='embed-area' dangerouslySetInnerHTML={{ __html: makeHTML(sharedModal.all_data) }}></div>
        </Grid>
        <Grid item xs={12} md={6}>
          <h2>Share</h2>
          <div className="share-buttons-in-modal">

            <TwitterShareButton url={shareUrl} title="Check this out!"><TwitterIcon /></TwitterShareButton>
            <FacebookShareButton url={shareUrl} quote="Check this out!"><FacebookIcon /></FacebookShareButton>
            <WhatsappShareButton url={shareUrl} title="Check this out!"><WhatsappIcon /></WhatsappShareButton>
            <TelegramShareButton url={shareUrl} title="Check this out!"><TelegramIcon /></TelegramShareButton>
            <LinkedinShareButton url={shareUrl} title="Check this out!"><LinkedinIcon /></LinkedinShareButton>
            <PinterestShareButton url={shareUrl} title="Check this out!"><PinterestIcon /></PinterestShareButton>
            <RedditShareButton url={shareUrl} title="Check this out!"><RedditIcon /></RedditShareButton>
            <TumblrShareButton url={shareUrl} title="Check this out!"><TumblrIcon /></TumblrShareButton>
            <LineShareButton url={shareUrl} title="Check this out!"><LineIcon /></LineShareButton>
          </div>
          <h2>Rate it</h2>
          <Rating
            style={{ maxWidth: 180 }}
            value={rating}
            onChange={setRatingHandler}
            isRequired
          />
        </Grid>
      </Grid>
    </Box>
  </Modal>)
}