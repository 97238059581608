import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Masonry from '@mui/lab/Masonry';
import VideoEmbed from './components/VideoEmbed';
import { Button, Modal, TextField } from '@mui/material';
import TopNav from './components/TopNav';
import { form_obj, req, Storage } from './CommonLibrary';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignInForEntryModal from './components/SignInForEntryModal';
import ConfirmEntry from './components/ConfirmEntry';
import GoogleLogin from 'react-google-login';
import SharedModal from './components/SharedModal';
import InfiniteScroll from "react-infinite-scroll-component";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
let entry = false;
let videosArray = [];
let videoData = [];
let currentPage = 1;
export default function BasicMasonry() {
  /**

   * 4) show logged in users entries at top
   * 5) add delete entry for logged in users
   * 6) light / dark modes
   * 7) stress test 10k entries 100k votes - done
   * 8) add GA stats
   * 9) admin leader board
   * 10) prevent duplicate entries
   * 11) search and sort 
   */
  const store = new Storage();
  const [userVotes, setUserVotes] = useState([]);
  const [masonColumns, setMasonColumns] = useState(1);//asume mobile
  const [entryModalShowing, setEntryModalShowing] = useState(false);
  const [videoPreview, setVideoPreview] = useState('');
  const [modalState, setModalState] = useState('');
  const [entries, setEntries] = useState([]);
  const [moreVideos, setMoreVideos] = useState(true);
  const [voteModalShowing, setVoteModalShowing] = useState(false);
  const [signInVote, setSignInVote] = useState(false);
  const [sharedModal, setSharedModal] = useState(false);
  function getInstagramVideoId(url) {
    const regex = /\/([A-Za-z0-9-_]{11})/;
    const match = url.match(regex);
    if (match && match[1]) {
      return match[1];
    } else {
      const urlObject = new URL(url);
      const paths = urlObject.pathname.split('/');
      const videoIndex = paths.findIndex((path) => path === 'p' || path === 'tv' || path === 'reel');
      if (videoIndex !== -1 && paths[videoIndex + 1]) {
        return paths[videoIndex + 1];
      } else {
        console.error('Could not find video id in url', url);
        return false;
      }
    }
    return false;
  }
  const showEntryModal = () => {
    setModalState("");
    entry = false;
    setEntryModalShowing(true);
  }
  const handleEntryModalClose = () => {
    entry = false;
    setEntryModalShowing(false);
  }
  const isUrl = (url) => {
    //using new Url
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  }
  const getVideos = async (sort = "default") => {
    let url = "/get_videos?page=" + currentPage + "&sort=" + sort;
    let data = await req("get", url);
    if (data.videos.length) {
      videoData = [...videoData, ...data.videos];
      let newData = []
      for (let i = 0; i < data.videos.length; i++) {
        newData.push(<VideoEmbed showVoteModal={showVoteModal} key={data.videos[i].entry_id} video={data.videos[i]} votes={userVotes} />);
      }

      currentPage++;
      return setEntries([...entries, ...newData]);
    }else{
      setMoreVideos(false);
      console.log("no more videos");
    }
  }
  const getVotes = async (ids) => {
    let user = store.get("_rf3vc_auth");
    if (!user)
      return
    let data = await req("get", "/get_user_votes?entries=" + encodeURIComponent(ids));
    setUserVotes(data.votes);
  }

  const getVideoData = async (url) => {
    setModalState("loading");
    if (url.includes("instagram")) {
      let ig_id = getInstagramVideoId(url);
      if (!ig_id) {
        toast.error("Unable to locate instagram video.");
      }
      console.log("instagram", url);
      //todo use local iframe
      setVideoPreview(`<iframe width="100%" height="300"><blockquote class="instagram-media" data-instgrm-permalink="${url}" data-instgrm-version="14"></blockquote><script src="https://www.instagram.com/embed.js"></script></iframe>`);
      //addscript("https://www.instagram.com/embed.js");
      entry = {
        html: `<blockquote class="instagram-media" data-instgrm-permalink="${url}" data-instgrm-version="14"></blockquote>`,
        url: url,
        provider_name: "instagram",
        id: ig_id
      }
      setModalState("confirmEntry");
      return
    }
    let response = await req("GET", "/video_data?url=" + encodeURI(url));

    if (!response) {
      toast.error("Unable to get video data");
      setModalState("");
      setVideoPreview("");
      return;
    }
    function getYouTubeVideoId(url) {
      const regex = /[?&]v=([A-Za-z0-9_-]{11})|youtu.be\/([A-Za-z0-9_-]{11})|(?:youtube.com\/|youtu.be\/)shorts\/([A-Za-z0-9_-]{11})/;
      const match = url.match(regex);
      if (match) {
        return match[1] || match[2] || match[3];
      } else {
        throw new Error('Invalid YouTube URL');
      }
    }


    let id = false;
    if (response.embed_product_id)
      id = response.embed_product_id;
    else
      id = getYouTubeVideoId(url);
    entry = {
      html: response.html,
      url: url,
      type: response.type,
      id,
      ...response
    }
    setVideoPreview(response.html);
    setModalState("confirmEntry");
  }


  const handleEntry = async (e) => {
    e.preventDefault();
    entry = false;
    let videoUrl = e.target.url.value;
    if (!isUrl(videoUrl)) {
      toast.error("Please enter a valid URL");
      return;
    }

    await getVideoData(videoUrl);

  }
  const handleSharedEntry = async () => {
    const hashValue = window.location.hash;
    if (hashValue.startsWith('#share=')) {
      let entry = hashValue.slice(7);
      if (entry.includes("entry_")) {
        let shared_entry = await req("GET", "/get_entry?entry=" + entry);
        if (shared_entry) {
          shared_entry.entry_id = entry;
          setSharedModal(shared_entry);
        }
      }
    }

  }
  const notVideo = (e) => {
    e.preventDefault();
    setModalState("");
  }
  const isVideo = async (e) => {
    e.preventDefault();
    let user = store.get("_rf3vc_auth");
    if (!user) {
      setModalState("whoAreYou");//show sign in modal
      return;
    } {
      let response = await req("POST", "/add_entry", { entry });
      if (response.data) {
        //template {"entry_id":"entry_1676849868760","time_created":1676849868843,"rating":5,"votes":1,"provider_id":"7188980551733218586","provider_name":"TikTok"}
        let video = <VideoEmbed showVoteModal={showVoteModal} key={response.data.entry_id} video={response.data} votes={userVotes} />;
        setEntries([video, ...entries]);
        setModalState("");//show sign in modal
        showEntryModal(false);
      }

    }
    //if not logged in show sign up
    //else add entry and show their link
  }
  const voteGoogleResponse = async (response) => {
    let data = {
      google_response: response,
      name: response.profileObj.name,
      email: response.profileObj.email,
      entry: signInVote.entry,
      rating: signInVote.rating
    }
    let res = await req("POST", "/vote", data);
    if (res.token) {
      store.set("_rf3vc_auth", res.token);
      toast.success("Vote submitted");
      setVoteModalShowing(false);
      setSignInVote(false);
    }
  }

  const addscript = (src) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    document.body.appendChild(script);
  }
  const showVoteModal = (vote) => {
    setSignInVote(vote);
    setVoteModalShowing(true);
  }
  const handleVoteModalClose = () => {
    setVoteModalShowing(false);
    setSignInVote(false);
  }
  const handleVoteSignIn = async (e) => {
    e.preventDefault();
    //todo add loading and toaster response
    let data = form_obj(e.target);
    data.entry = signInVote.entry;
    data.rating = signInVote.rating;
    let response = await req("POST", "/vote", data);
    if (response.token) {
      store.set("_rf3vc_auth", response.token);
      toast.success("Vote submitted");

    }
    setVoteModalShowing(false);
    setSignInVote(false);
    console.log("response", response);
  }

  let mounted = false
  useEffect(() => {
    if (mounted) return;
    mounted = true;
    getVideos();
    handleSharedEntry();
    let width = window.innerWidth;
    let cols = 1
    if (width > 600) {
      cols = 2;
    }
    if (width > 960) {
      cols = 3;
    }
    if (width > 1280) {
      cols = 4;
    }
    setMasonColumns(cols);
  }, []);
  useEffect(() => {
    console.log("updated entries", entries);
  }, [entries]);
  if (!entries.length) {
    return (<>Loading...</>)
  }
  console.log("render items", entries,moreVideos);
  return (<>
    <TopNav showEntryModal={showEntryModal} />
    <div className="container">


        <InfiniteScroll
          dataLength={entries.length} //This is important field to render the next data
          next={getVideos}
          hasMore={moreVideos}
          loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <Masonry columns={masonColumns} spacing={2}>
            {entries}
          </Masonry>
        </InfiniteScroll>
        <h2>{entries.length} Entries</h2>

      <Modal
        open={entryModalShowing}
        onClose={handleEntryModalClose}
        aria-labelledby="enter-contest-modal"
        aria-describedby="enter-contest-modal"
      >
        <Box className="enter-modal">
          {modalState == "loading" && <div className='loading-area'><img src="https://app.rewardsfuel.com/assets/images/loader.svg" /></div>}
          {modalState == "" &&
            <form onSubmit={handleEntry}>
              <h2>Enter the contest</h2>
              <TextField type="url" placeholder="https://" name="url" fullWidth id="outlined-basic" label="Video URL" variant="outlined" />
              <div className='entry-instructions'>Copy and paste your video URL, from YouTube, TikTok, or Instagram.</div>
              <Button fullWidth type='submit' variant="contained">Enter</Button>
            </form>
          }
          {modalState == "confirmEntry" &&
            <ConfirmEntry videoPreview={videoPreview} isVideo={isVideo} notVideo={notVideo} />
          }
          {modalState == "whoAreYou" && <SignInForEntryModal entry={entry} />}
        </Box>
      </Modal>
      <Modal
        open={voteModalShowing}
        onClose={handleVoteModalClose}
        aria-labelledby="vote-contest-modal"
        aria-describedby="vote-contest-modal"
      >
        <Box className="vote-modal">
          <h2>Almost done</h2>
          <p>For your vote to count we need to know who you are.</p>
          <form className='who-are-you-form' onSubmit={handleVoteSignIn}>
            <TextField name="name" className='mb-2' fullWidth required min="3" id="outlined-basic" label="Name" variant="outlined" />
            <TextField name="email" className='mb-2' fullWidth type="email" id="outlined-basic" label="Email" variant="outlined" />
            <Button type="submit" className='mb-2' fullWidth variant='contained'>Enter Contest</Button>
          </form>
          <GoogleLogin
            className='w-100'
            clientId="388528219724-etp5582e4ftne8rk5ddhp33sd8nq8mie.apps.googleusercontent.com"
            buttonText="Login"
            onSuccess={voteGoogleResponse}
          />
        </Box>
      </Modal>
      <SharedModal showVoteModal={showVoteModal} sharedModal={sharedModal} />
      <ToastContainer />
    </div>
  </>
  );
}
