import { Button,  Menu, Grid } from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';
import { req, Storage } from '../CommonLibrary';
import { Rating } from '@smastrom/react-rating'
import '@smastrom/react-rating/style.css'
import {
  EmailShareButton,
  FacebookShareButton,
  LineShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,

} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LineIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";
import { useEffect, useRef, useState } from "react";
export default function VideoEmbed(props) {

  const video = props.video;
  const votes = props.votes;
  const voteModal = props.showVoteModal;
  let userRating = 0;
  if (votes.length) {
    try {
      let vote = votes.find(vote => vote.entry_id === video.entry_id);
      if (vote) {
        userRating = vote.rating;
      }
    } catch (e) {
      console.log(e);
    }
  }
  //if theres a vote for this then user it
  let store = new Storage();
  const [shareOpen, setShareOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rating, setRating] = useState(0);//todo add in usere's rating
  const setRatingHandler = (rating) => {
    setRating(rating);
    let user = store.get("_rf3vc_auth");
    if (!user) {//show modal letting them know they need to sign in to vote and they can win something
      voteModal({ rating, entry: props.video.entry_id });
    }
    let result = req("POST", "/vote", { rating, entry: props.video.entry_id });

  }
  const makeHTML = (entry) => {
    if (entry.provider_name === "instagram") {
      return `<iframe loading="lazy" sandbox="allow-scripts allow-presentation allow-same-origin" class="ig-video" src="https://www.instagram.com/reel/${entry.provider_id}/embed/" title="instagram post" frameborder="0" ></iframe>`
    }
    else if (entry.provider_name === "YouTube") {
    return `<iframe loading="lazy" sandbox="allow-scripts allow-presentation allow-same-origin" class="yt-video" src="https://www.youtube.com/embed/${entry.provider_id}" title="YouTube video player" frameborder="0" ></iframe>`
    }
    else if (entry.provider_name === "TikTok") {
      return `<iframe loading="lazy" sandbox="allow-scripts allow-presentation allow-same-origin" class="tt-video" src="https://www.tiktok.com/embed/v2/${entry.provider_id}" ></iframe>`;
    }
  }
  let shareUrl = window.location.href;
  if(shareUrl.includes("#")){
    shareUrl = shareUrl.substring(0,shareUrl.indexOf("#"));
  }//if there's already tag clear it and use the new one
  shareUrl = shareUrl + "#share=" + video.entry_id;
  const handleShareClick = (event) => {
    setShareOpen(true);
    setAnchorEl(event.currentTarget);
  };
  const getUsersVote =async()=>{
    //if not logged in return
    let user = store.get("_rf3vc_auth");
    if (!user) {
      return;
    }
    let result = await req("GET", "/get_vote?entry_id="+props.video.entry_id);
    console.log("users vote",result);
    if(parseInt(result) !== parseInt(rating)){
      setRating(parseInt(result));
    }
  }
  const handleShareClose = () => {
    setShareOpen(false);
    setAnchorEl(null);
  };
  let mounted = false;
  useEffect(() => {
    if(mounted)return;
    mounted = true;
    getUsersVote();
  }, []);
  return (<div className="video-container">
  
    <div dangerouslySetInnerHTML={{ __html: makeHTML(video) }}></div>
    <div className="post-video-area">
      <Grid spacing={2} container>
        <Grid className="text-left" item xs={6}>
          <Button fullWidth={false} onClick={handleShareClick}><ShareIcon /></Button>
          <Menu
            id="share-menu"
            open={shareOpen}
            anchorEl={anchorEl}
            onClose={handleShareClose}
            className="share-menu"
            MenuListProps={{
              'aria-labelledby': 'share-button',
            }}
          >
            <TwitterShareButton url={shareUrl} title="Check this out!"><TwitterIcon /></TwitterShareButton>
            <FacebookShareButton url={shareUrl} quote="Check this out!"><FacebookIcon /></FacebookShareButton>
            <WhatsappShareButton url={shareUrl} title="Check this out!"><WhatsappIcon /></WhatsappShareButton>
            <TelegramShareButton url={shareUrl} title="Check this out!"><TelegramIcon /></TelegramShareButton>
            <LinkedinShareButton url={shareUrl} title="Check this out!"><LinkedinIcon /></LinkedinShareButton>
            <PinterestShareButton url={shareUrl} title="Check this out!"><PinterestIcon /></PinterestShareButton>
            <RedditShareButton url={shareUrl} title="Check this out!"><RedditIcon /></RedditShareButton>
            <TumblrShareButton url={shareUrl} title="Check this out!"><TumblrIcon /></TumblrShareButton>
            <LineShareButton url={shareUrl} title="Check this out!"><LineIcon /></LineShareButton>

          </Menu>
        </Grid>
        <Grid className="text-right" item xs={6}>
          <Rating
            style={{ maxWidth: 180 }}
            value={rating}
            onChange={setRatingHandler}
            isRequired
          />
        </Grid>
      </Grid>
    </div>
  </div>);
}