import React from 'react';
import { Button, ButtonGroup, Grid } from '@mui/material';
export default function ConfirmEntry(props) {
    const { videoPreview, notVideo, isVideo } = props;
    return (<><h2 className='text-center'>Confirm your entry please.</h2>
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <div>Is this your video?</div>
                <div>
                    <ButtonGroup fullWidth variant="contained" aria-label="outlined primary button group">
                        <Button onClick={notVideo}>No</Button>
                        <Button onClick={isVideo}>Yes</Button>
                    </ButtonGroup>
                </div>
            </Grid>
            <Grid item xs={12} md={8}><div className='video-preview-entry' dangerouslySetInnerHTML={{ __html: videoPreview }}></div></Grid>
        </Grid></>);
}